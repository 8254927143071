"use strict";

var $window = jQuery(window);

// Content Aside
var contentAside = {
  visible: function visible() {
    jQuery('.aside-wrapper').removeClass().addClass('aside-wrapper--visible');
  },
  hidden: function hidden() {
    jQuery('.aside-wrapper').removeClass().addClass('aside-wrapper--hidden');
    c;
  },
  handler: function handler() {
    jQuery(document).on('click', '.page-up-button, .popup-up-button', function () {
      return gsap.to(jQuery('html, body'), {
        duration: 0.4,
        scrollTop: 0
      });
    });
  },
  "break": function _break() {
    var $asideWrapper = jQuery('.aside-wrapper');
    if (!$asideWrapper.length) return;
    var st = window.pageYOffset;
    var wh = window.innerHeight;
    var offsetY = jQuery('.app-footer-content').offset().top - wh + 70;
    var asideVisibleClass = 'is-visible';
    var asideHiddenClass = 'is-hidden';
    var asideBreakClass = 'is-break';
    if (st >= wh / 2) {
      $asideWrapper.removeClass(asideHiddenClass).addClass(asideVisibleClass);
    } else {
      $asideWrapper.removeClass(asideVisibleClass).addClass(asideHiddenClass);
    }

    // break
    if (st >= offsetY) {
      $asideWrapper.addClass(asideBreakClass);
      jQuery('.app-aside-menu').addClass(asideBreakClass);
    } else {
      $asideWrapper.removeClass(asideBreakClass);
      jQuery('.app-aside-menu').removeClass(asideBreakClass);
    }
  }
};

// Tab
var tab = {
  init: function init() {
    jQuery('.tab--lv1, .tab--lv2, .tab--lv4').addClass('tab');
    this.handler();
  },
  select: function select(v) {
    var $this = jQuery(v.currentTarget);
    if ($this.closest('.tab--lv1, .tab--lv2, .tab--lv4').hasClass('is-page-only')) return;
    var id = $this.attr('id');
    $this.siblings().attr('aria-selected', false);
    $this.attr('aria-selected', true);
    $this.parent('[role=tablist]').siblings('[role=tabpanel]').attr('hidden', true);
    $this.parent('[role=tablist]').siblings('[aria-labelledby=' + id + ']').removeAttr('hidden');
  },
  handler: function handler() {
    var _this = this;
    jQuery('.tab').on('click', '[role=tab], [role=button]', function (e) {
      return _this.select(e);
    });
  }
};

// Tool tip
var tooltip = {
  init: function init() {
    jQuery(document).on('click', function () {
      return tooltip.close();
    });
    jQuery('.tooltip-aside-container').on('click', function (e) {
      e.stopPropagation();
    });
    this.handler();
  },
  handler: function handler() {
    var _this2 = this;
    jQuery('.tooltip-button').on('click', function (e) {
      return _this2.open(e);
    });
    jQuery('.tooltip-aside-close-button').on('click', function (e) {
      return _this2.open(e);
    });
  },
  open: function open(v) {
    var $this = jQuery(v.currentTarget);
    var isActive = $this.closest('.tooltip').find('.tooltip-aside-container').hasClass('is-active');
    if (!isActive) {
      $this.closest('.tooltip').find('.tooltip-aside-container').addClass('is-active');
    } else {
      $this.closest('.tooltip').find('.tooltip-aside-container').removeClass('is-active');
    }
    return false;
  },
  close: function close() {
    jQuery('.tooltip-aside-container').removeClass('is-active');
  }
};

// Sign info
var signInfo = {
  init: function init() {
    jQuery(document).on('click', function () {
      return signInfo.close();
    });
    jQuery('.sign-info-main').on('click', function (e) {
      e.stopPropagation();
    });
    this.handler();
  },
  handler: function handler() {
    var _this3 = this;
    jQuery('.sign-info-container').on('mouseenter', function (e) {
      return _this3.open(e);
    }).on('mouseleave', function (e) {
      return _this3.close();
    });
  },
  open: function open(v) {
    var $this = jQuery(v.currentTarget);
    var isActive = $this.closest('.sign-info-container').find('.sign-info-main').hasClass('is-active');
    if (!isActive) {
      $this.closest('.sign-info-container').find('.sign-info-main').addClass('is-active');
    } else {
      $this.closest('.sign-info-container').find('.sign-info-main').removeClass('is-active');
    }
    return false;
  },
  close: function close() {
    jQuery('.sign-info-container').find('.sign-info-main').removeClass('is-active');
  }
};

// Form
var form = {
  init: function init() {
    this.getReadonly();
    this.getDisabled();
  },
  getReadonly: function getReadonly() {
    jQuery('input').each(function (e, v) {
      var readonly = jQuery(v).prop('readonly');
      if (readonly) {
        jQuery(v).parent().parent().addClass('is-readonly');
      }
    });
  },
  getDisabled: function getDisabled() {
    jQuery('input').each(function (e, v) {
      var disabled = jQuery(v).prop('disabled');
      if (disabled) {
        jQuery(v).parent().parent().addClass('is-disabled');
      }
    });
  },
  disabled: function disabled(e, v) {
    jQuery(e).prop('disabled', v);
    if (v) {
      jQuery(e).parent().parent().addClass('is-disabled');
    } else {
      jQuery(e).parent().parent().removeClass('is-disabled');
    }
  }
};

// Textarea
var formTextarea = {
  init: function init() {
    this.getReadonly();
    this.getDisabled();
  },
  getReadonly: function getReadonly() {
    jQuery('textarea').each(function (e, v) {
      var readonly = jQuery(v).prop('readonly');
      if (readonly) {
        jQuery(v).closest('.form-textarea').addClass('is-readonly');
      }
    });
  },
  getDisabled: function getDisabled() {
    jQuery('textarea').each(function (e, v) {
      var disabled = jQuery(v).prop('disabled');
      if (disabled) {
        jQuery(v).closest('.form-textarea').addClass('is-disabled');
      }
    });
  }
};

// Select
var formSelect = {
  init: function init() {
    this.setup();
    jQuery(document).on('click', function () {
      return formSelect.close();
    });
    jQuery('.select-list').on('click', function (e) {
      e.stopPropagation();
    });
  },
  setup: function setup() {
    if (!jQuery('.select').length) return;

    // 셀렉트 구조가 존재할 경우
    if (jQuery('.select-list').length) {
      jQuery('.select-list').remove();
      jQuery('.select-button').off('click');
      jQuery('.select-list').off('click');
    }

    // 셀렉트 구조 세팅
    jQuery('.select').each(function (i, v) {
      jQuery(v).find('select').after('<div class="select-list"><ul class="select-list-content"></ul></div>');
      jQuery(v).find('select option').each(function (i, v) {
        jQuery(v).closest('.select').find('.select-list > ul').append('<li><button type="button"><span>' + v.text + '</span></button></li>');

        // 선택된 옵션이 있을 때
        // if (jQuery(v).attr('selected')) {
        //   jQuery(v).closest('.select').addClass('is-selected');
        // }

        // 선택된 옵션을 버튼명으로 변경
        if (jQuery(v).prop('selected')) {
          var selectedText = jQuery(v).text();
          jQuery(v).closest('.select').find('.select-button > span').text(selectedText);
          jQuery(v).closest('.select').find('.select-list-content > li').eq(i).addClass('is-active');
          // if (jQuery(v).closest('.select').hasClass('is-selected')) {

          // }
        }
      });
    });

    formSelect.handler();
    formSelect.disabledAll();
  },
  handler: function handler() {
    var _this4 = this;
    jQuery('.select-button').on('click', function (e) {
      return _this4.open(e);
    });
    jQuery('.select-list').on('click', 'button', function (e) {
      return _this4.select(e);
    });
    // jQuery('.select select').on('change', (e) => this.change(e));
  },
  open: function open(e) {
    // this.close();
    var $this = jQuery(e.currentTarget);
    var isActive = $this.closest('.select').hasClass('is-active');
    if ($this.closest('.select').hasClass('is-disabled')) return;
    if (!isActive) {
      this.close();
      $this.closest('.select').addClass('is-active');
      var active = $this.closest('.select').find('.select-list-content .is-active').index();
      $this.closest('.select').find('.select-list-content').scrollTop(48 * (active - 2));
      var $target = $this.closest('.select');
      var $selectList = $this.parent().find('.select-list');
      var $appHeight = jQuery('body').height();
      var reverse = $appHeight < $target.offset().top + $target.outerHeight(true) + $selectList.height() + 50;
      if (reverse) {
        $this.parent().find('.select-list').addClass('is-reverse');
      } else {
        $this.parent().find('.select-list').removeClass('is-reverse');
      }
    } else {
      $this.closest('.select').removeClass('is-active');
    }
    return false;
  },
  close: function close() {
    jQuery('.select').removeClass('is-active');
    jQuery('.select-list').removeClass('is-reverse');
  },
  select: function select(e) {
    var $this = jQuery(e.currentTarget).closest('.select').find('select');
    var idx = jQuery(e.currentTarget).parent().index();
    // console.log($this, idx);
    $this.closest('.select').addClass('is-selected');
    this.change($this, idx);
    this.close();
  },
  change: function change(e, i) {
    var $this = e;
    var idx = i;
    // console.log($this, idx);
    var text = $this.find('option').eq(idx).text();
    $this.find('option:eq(' + idx + ')').prop('selected', true);
    $this.closest('.select').find('.select-list-content > li').removeClass('is-active').eq(idx).addClass('is-active');
    $this.parent().find('.select-button > span').text(text);
    $this.trigger('change');
  },
  valueChange: function valueChange(e, v) {
    var _this5 = this;
    // console.log('para:', i);
    var getValue = v;
    jQuery(e).find('option').each(function (i, v) {
      if (jQuery(v).val() == getValue) {
        _this5.change(e, i);
      }
    });
  },
  update: function update() {
    this.setup();
  },
  disabledAll: function disabledAll() {
    jQuery('.select select').each(function (e, v) {
      var disabled = jQuery(v).prop('disabled');
      if (disabled) {
        jQuery(v).parent().parent().addClass('is-disabled');
      }
    });
  },
  selected: function selected() {
    jQuery('.select select').each(function (e, v) {
      var disabled = jQuery(v).prop('disabled');
      if (disabled) {
        jQuery(v).parent().parent().addClass('is-disabled');
      }
    });
  },
  disabled: function disabled(e, v) {
    jQuery(e).prop('disabled', v);
    if (v) {
      jQuery(e).parent().parent().addClass('is-disabled');
    } else {
      jQuery(e).parent().parent().removeClass('is-disabled');
    }
  }
};

// Form Search
var formSearch = {
  init: function init() {
    var _this6 = this;
    if (!jQuery('.form-input--icon-button input').length) return;
    jQuery('.form-input--icon-button input').each(function (i, v) {
      _this6.keydown(v);
    });
    this.handler();
  },
  handler: function handler() {
    var _this7 = this;
    jQuery('.form-input--icon-button').on('focusin', 'input', function (e) {
      return _this7.focusIn(e);
    }).on('focusout', 'input', function (e) {
      return _this7.focusOut(e);
    }).on('keydown', 'input', function (e) {
      return _this7.keydown(e.currentTarget);
    }).on('keyup', 'input', function (e) {
      return _this7.keydown(e.currentTarget);
    }).on('click', '.delete-button', function (e) {
      return _this7["delete"](e);
    });
  },
  focusIn: function focusIn(e) {
    var $this = jQuery(e.currentTarget);
    $this.parent().parent().addClass('is-focusin');
    this.keydown(e.currentTarget);
  },
  focusOut: function focusOut(e) {
    var $this = jQuery(e.currentTarget);
    $this.parent().parent().removeClass('is-focusin');
    this.keydown(e.currentTarget);
  },
  keydown: function keydown(e) {
    var $this = jQuery(e);
    var val = $this.val();
    if (val != '') {
      $this.parent().parent().addClass('is-written');
    } else {
      $this.parent().parent().removeClass('is-written');
    }
  },
  "delete": function _delete(e) {
    var $this = jQuery(e.currentTarget);
    $this.closest('.form-input--icon-button').find('input')[0].value = null;
    $this.closest('.form-input--icon-button').find('input').focus();
  }
};

// GNB
var gnbAction = {
  init: function init() {
    this.handler();
  },
  handler: function handler() {
    var _this8 = this;
    jQuery('.ng-content').on('mouseenter', '>li', function () {
      return _this8.open();
    }).on('mouseleave', '>li', function () {
      return _this8.close();
    });
    jQuery('.depth2, .depth3--lv2').on('mouseenter', '> div', function () {
      return _this8.depth3Open();
    }).on('mouseleave', '> div', function () {
      return _this8.depth3Close();
    });
  },
  open: function open() {
    var gnbOpenH = jQuery('.content-main-wrapper').outerHeight(true);
    jQuery('.app-header .content-main').addClass('is-active');
    jQuery('.app-header .content-main').css('height', gnbOpenH + 'px');
  },
  close: function close() {
    // const gnbCloseH = isPageScale ? 35 : 44;

    jQuery('.app-header .content-main').removeClass('is-active');
    // jQuery('.app-header .content-main').css('height', gnbCloseH + 'px');
    jQuery('.app-header .content-main').css('height', '35px');
  },
  depth3Open: function depth3Open() {
    if (!jQuery('.depth3--lv2').length) return;
    jQuery('.depth3--lv2').addClass('is-active');
  },
  depth3Close: function depth3Close() {
    if (!jQuery('.depth3--lv2').length) return;
    jQuery('.depth3--lv2').removeClass('is-active');
  }
};

// Header
var appHeader = {
  init: function init() {
    // jQuery(document).on('click', () => {
    //   appHeader.recommendContentClose();
    //   appHeader.automaticContentClose();
    // });
    // jQuery('.contents-rcd-contents, .contents-am-contents').on('click', (e) => {
    //   e.stopPropagation();
    // });
    this.handler();
  },
  handler: function handler() {
    var _this9 = this;
    // jQuery('.app-header .contents-search .form-input--icon-button')
    //   .on('click', 'input', (e) => this.focusIn(e))
    //   .on('keydown', 'input', (e) => this.keydown(e.currentTarget));
    // jQuery('.app-header .contents-rcd-contents').on(
    //   'click',
    //   '.delete-button',
    //   (e) => this.recentSearchDelete(e),
    // );
    jQuery('.app-header').on('click', '.menu-label', function () {
      return _this9.menuControl();
    });
  },
  focusIn: function focusIn(e) {
    this.recommendContentOpen();
    this.keydown(e.currentTarget);
    return false;
  },
  focusOut: function focusOut(e) {
    this.recommendContentClose();
    this.keydown(e.currentTarget);
  },
  keydown: function keydown(e) {
    var $this = jQuery(e);
    var val = $this.val();
    if (val != '') {
      this.automaticContentOpen();
    } else {
      this.automaticContentClose();
    }
  },
  recommendContentOpen: function recommendContentOpen() {
    jQuery('.contents-rcd-contents').addClass('is-active');
  },
  recommendContentClose: function recommendContentClose() {
    jQuery('.contents-rcd-contents').removeClass('is-active');
  },
  automaticContentOpen: function automaticContentOpen() {
    jQuery('.contents-am-contents').addClass('is-active');
  },
  automaticContentClose: function automaticContentClose() {
    jQuery('.contents-am-contents').removeClass('is-active');
  },
  recentSearchDelete: function recentSearchDelete(e) {
    var $this = jQuery(e.currentTarget);
    $this.closest('li').remove();
    if (!jQuery('.contents-rcd-contents .recent li').length) {
      jQuery('.contents-rcd-contents .recent ul').remove();
      jQuery('.recent-no-data').addClass('is-active');
    }
  },
  menuControl: function menuControl() {
    var menuState = jQuery('#menu-state').prop('checked');
    if (!menuState) {
      this.allMenuOpen();
    } else {
      this.allMenuClose();
    }
  },
  allMenuOpen: function allMenuOpen() {
    jQuery('html').addClass('is-menu');
  },
  allMenuClose: function allMenuClose() {
    jQuery('html').removeClass('is-menu');
  }
};

// Datepicker
var datePicker = {
  init: function init() {
    if (!jQuery('.form-input--datepicker input').length) return;
    this.api();
    jQuery.datepicker._gotoToday = function (id) {
      jQuery(id).datepicker('setDate', new Date()).datepicker('hide').blur();
    };
  },
  api: function api() {
    jQuery('.form-input--datepicker input').datepicker({
      changeMonth: true,
      changeYear: true,
      yearRange: 'c-5:c+4',
      showButtonPanel: true,
      showMonthAfterYear: true,
      currentText: 'Today',
      closeText: 'Delete',
      dateFormat: 'yy-mm-dd',
      monthNamesShort: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
      beforeShow: function beforeShow(e) {
        var target = jQuery(e);
        var calendar = jQuery('.ui-datepicker');
        var offsetY = target.offset().top + target.outerHeight(true);
        calendar.css('top', offsetY + 'px');
        setTimeout(function () {
          return calendar.css('top', offsetY + 'px');
        });
      },
      onSelect: function onSelect() {
        jQuery(this).parent().parent().addClass('is-active');
        console.log('default onSelect');
      },
      onClose: function onClose() {
        if (jQuery(window.event.srcElement).hasClass('ui-datepicker-close')) {
          jQuery(this).val('');
        }
      }
    });
  },
  apiValid: function apiValid(func, id) {
    if (id === undefined) return;
    if (jQuery(id).hasClass('hasDatepicker')) jQuery(id).datepicker('destroy');
    jQuery(id).datepicker({
      changeMonth: true,
      changeYear: true,
      yearRange: 'c-5:c+4',
      showButtonPanel: true,
      showMonthAfterYear: true,
      currentText: 'Today',
      closeText: 'Delete',
      dateFormat: 'yy-mm-dd',
      monthNamesShort: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
      beforeShow: function beforeShow(e) {
        var target = jQuery(e);
        var calendar = jQuery('.ui-datepicker');
        var offsetY = target.offset().top + target.outerHeight(true);
        calendar.css('top', offsetY + 'px');
        setTimeout(function () {
          return calendar.css('top', offsetY + 'px');
        });
      },
      onSelect: function onSelect() {
        jQuery(this).parent().parent().addClass('is-active');
        if (func != null) func();
      },
      onClose: function onClose() {
        if (jQuery(window.event.srcElement).hasClass('ui-datepicker-close')) {
          jQuery(this).val('');
        }
      }
    });
  }
};

// Depth2
var depth2Swiper;
var depth2 = {
  init: function init() {
    this.swiper();
  },
  swiper: function swiper() {
    if (!jQuery('.depth2 .depth2-container').length) return;
    depth2Swiper = new Swiper('.depth2 .depth2-container', {
      slidesPerView: 'auto',
      simulateTouch: false,
      watchOverflow: true,
      navigation: {
        nextEl: '.depth2 .depth2-button-next',
        prevEl: '.depth2 .depth2-button-prev'
      },
      on: {
        init: function init() {
          var $this = jQuery(this.$el);
          var idx = $this.attr('data-active-idx');
          $this.find('li').eq(idx).addClass('is-active');
          this.slideTo(idx, 0);
          this.update();
        }
      }
    });
  }
};

// Depth3
var depth3 = {
  init: function init() {
    var $this = jQuery('.depth3-container');
    var idx = $this.attr('data-active-idx');
    $this.find('li').eq(idx).find('[role="button"]').addClass('is-active');
  }
};

// Modal
var $modal = document.querySelectorAll('[data-modal-name]');
var modal = {
  init: function init() {
    modal.update();
  },
  open: function open(v) {
    bodyfixed('hidden');
    $modal.forEach(function (i) {
      if (v == i.getAttribute('data-modal-name')) {
        i.classList.add('modal--open');
      }
    });
  },
  close: function close(v) {
    bodyfixed('visible');
    if (v === undefined) {
      var $modalVisible = jQuery('[data-modal-name]:visible');
      $modalVisible.removeClass('modal--open');
    } else {
      if (v.type === 'click') {
        var $this = jQuery(v.currentTarget).closest('[data-modal-name]');
        $this.removeClass('modal--open');
      } else {
        var _$modal = document.querySelectorAll('[data-modal-name]');
        _$modal.forEach(function (i) {
          if (v == i.getAttribute('data-modal-name')) {
            i.classList.remove('modal--open');
          }
        });
      }
    }
  },
  addHandlerTrigger: function addHandlerTrigger(v) {
    var _this10 = this;
    v.not('is-js').each(function (i, v) {
      jQuery(v).on('click', function (e) {
        var modalName = e.currentTarget.getAttribute('data-modal-name');
        _this10.open(modalName);
      });
      jQuery(v).addClass('is-js');
    });
  },
  removeHandlerTrigger: function removeHandlerTrigger(v) {
    v.off('click');
  },
  addHandlerClose: function addHandlerClose(v) {
    var _this11 = this;
    v.not('is-js').each(function (i, v) {
      jQuery(v).on('click', function (e) {
        _this11.close(e);
        formSelect.close();
      });
      jQuery(v).addClass('is-js');
    });
  },
  removeHandlerClose: function removeHandlerClose(v) {
    v.off('click');
  },
  update: function update() {
    modal.addHandlerTrigger(jQuery('.modal-trigger-js'));
    this.addHandlerClose(jQuery('.modal-close-button, .modal__close-button-js'));
  }
};

// bodyfixed
var bodyfixed = function bodyfixed(v) {
  var $html = jQuery('html');
  if (v == 'hidden') {
    $html.addClass('is-modal');
  } else if (v == 'visible') {
    $html.removeClass('is-modal');
  }
};

// Main Card Swiper
var mainCardSwiper;
var mainCard = {
  init: function init() {
    this.swiper();
  },
  swiper: function swiper() {
    if (!jQuery('.main-card-swiper').length) return;
    jQuery('.main-card-swiper').each(function (i, e) {
      jQuery(e).closest('.section, .section--full').attr('data-main-card-swiper-area', i);
      mainCardSwiper = new Swiper(e, {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 40,
        simulateTouch: false,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.section[data-main-card-swiper-area="' + i + '"] .main-card-swiper-pagination, .section--full[data-main-card-swiper-area="' + i + '"] .main-card-swiper-pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.section[data-main-card-swiper-area="' + i + '"] .main-card-swiper-next, .section--full[data-main-card-swiper-area="' + i + '"] .main-card-swiper-next',
          prevEl: '.section[data-main-card-swiper-area="' + i + '"] .main-card-swiper-prev, .section--full[data-main-card-swiper-area="' + i + '"] .main-card-swiper-prev'
        },
        on: {
          init: function init() {
            if (jQuery(e).find('.swiper-slide').length < 5) {
              jQuery(e).parent().parent().find('.main-card-ctl').hide();
            }
          }
        }
      });
    });
  }
};

// Post Swiper
var postSwiper;
var post = {
  init: function init() {
    this.swiper();
  },
  swiper: function swiper() {
    if (!jQuery('.post-swiper').length) return;
    var postSwiperBetween = jQuery('.popup-main').length ? 20 : 40;
    jQuery('.post-swiper').each(function (i, e) {
      jQuery(e).closest('.section, .section--full').attr('data-post-swiper-area', i);
      postSwiper = new Swiper(e, {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: postSwiperBetween,
        simulateTouch: false,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.section[data-post-swiper-area="' + i + '"] .post-swiper-pagination, .section--full[data-post-swiper-area="' + i + '"] .post-swiper-pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.section[data-post-swiper-area="' + i + '"] .post-swiper-next, .section--full[data-post-swiper-area="' + i + '"] .post-swiper-next',
          prevEl: '.section[data-post-swiper-area="' + i + '"] .post-swiper-prev, .section--full[data-post-swiper-area="' + i + '"] .post-swiper-prev'
        },
        on: {
          init: function init() {
            if (jQuery(e).find('.swiper-slide').length < 5) {
              jQuery(e).parent().parent().find('.post-swiper-ctl').hide();
            }
          }
        }
      });
    });
  }
};

// Accordion
var accordion = {
  init: function init() {
    this.handler();
  },
  handler: function handler() {
    var _this12 = this;
    jQuery('.accordion').each(function (i, v) {
      if (jQuery(v).hasClass('is-js')) return;
      jQuery(v).on('click', '.accordion-button > strong', function (e) {
        _this12.toogle(e);
      });
      jQuery(v).addClass('is-js');
    });
    // jQuery('.accordion').on('click', '.accordion-button > strong', (e) =>
    //   this.toogle(e),
    // );
  },
  toogle: function toogle(e) {
    var $this = jQuery(e.currentTarget);
    $this.parent().parent().toggleClass('is-active').siblings().removeClass('is-active');
    return false;
  },
  update: function update() {
    var _this13 = this;
    jQuery('.accordion').not('.is-js').each(function (i, v) {
      jQuery(v).on('click', '.accordion-button > strong', function (e) {
        _this13.toogle(e);
      });
      jQuery(v).addClass('is-js');
    });
  }
};

// Post Sort
var postSort = {
  init: function init() {
    this.handler();
  },
  handler: function handler() {
    var _this14 = this;
    jQuery('.sort-method').on('click', '[role=button]', function (e) {
      return _this14.click(e);
    });
  },
  click: function click(e) {
    var $this = jQuery(e.currentTarget);
    var $postContainer = jQuery('.post-container');
    var idx = $this.index();
    $postContainer.removeClass('view-type01 view-type02');
    if (idx === 0) {
      $postContainer.addClass('view-type01');
    } else {
      $postContainer.addClass('view-type02');
    }
    $this.addClass('is-active').siblings().removeClass('is-active');
  }
};

// Sub Main Visual
var subMainVisual;
var subMainVisualSwiper = {
  init: function init() {
    if (!jQuery('.sub-main-visual-swiper').length) return;
    this.swiper();
    this.isAutoplay();
    this.handler();
  },
  handler: function handler() {
    var _this15 = this;
    jQuery('.swiper-ctrl-container').on('click', '.play-button', function () {
      return _this15.autoplayStart();
    }).on('click', '.pause-button', function () {
      return _this15.autoplayPause();
    });
  },
  swiper: function swiper() {
    subMainVisual = new Swiper('.sub-main-visual-swiper', {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      simulateTouch: false,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-ctrl-container .swiper-pagination'
        // clickable: true,
      }
    });
  },
  autoplayStart: function autoplayStart() {
    subMainVisual.autoplay.start();
    this.isAutoplay();
  },
  autoplayPause: function autoplayPause() {
    subMainVisual.autoplay.stop();
    this.isAutoplay();
  },
  isAutoplay: function isAutoplay() {
    if (subMainVisual.autoplay.running) {
      jQuery('.section-sub-main').attr('data-autoplay', 'true');
    } else {
      jQuery('.section-sub-main').attr('data-autoplay', 'false');
    }
  }
};

// My 학습 현황
var myLearningStatusSwiper;
var myLearningStatus = {
  init: function init() {
    if (!jQuery('.learning-status-swiper').length) return;
    this.swiper();
  },
  swiper: function swiper() {
    if (!jQuery('.learning-status-swiper').length) return;
    jQuery('.learning-status-swiper').each(function (i, e) {
      jQuery(e).closest('.tab-pannel').attr('data-learning-status-swiper-area', i);
      myLearningStatusSwiper = new Swiper(e, {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        simulateTouch: false,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        pagination: {
          el: '[data-learning-status-swiper-area="' + i + '"] .slide-ctrl-pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: '[data-learning-status-swiper-area="' + i + '"] .slide-ctrl-button-next',
          prevEl: '[data-learning-status-swiper-area="' + i + '"] .slide-ctrl-button-prev'
        }
      });
    });
  }
};

// 메인
var mainVisualSwiper;
var mainVisual = {
  init: function init(sec) {
    if (!jQuery('.main-visual-swiper').length) return;
    this.swiper(sec);
    this.isAutoplay();
    this.handler();
  },
  handler: function handler() {
    var _this16 = this;
    jQuery('.swiper-ctrl-container').on('click', '.play-button', function () {
      return _this16.autoplayStart();
    }).on('click', '.pause-button', function () {
      return _this16.autoplayPause();
    });
  },
  swiper: function swiper(sec) {
    mainVisualSwiper = new Swiper('.main-visual-swiper', {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      simulateTouch: false,
      watchOverflow: true,
      loop: true,
      autoplay: {
        delay: sec,
        disableOnInteraction: false
      },
      pagination: {
        el: '.main-visual-swiper .swiper-pagination'
      },
      navigation: {
        nextEl: '.main-visual-button-next',
        prevEl: '.main-visual-button-prev'
      }
    });
  },
  autoplayStart: function autoplayStart() {
    mainVisualSwiper.autoplay.start();
    this.isAutoplay();
  },
  autoplayPause: function autoplayPause() {
    mainVisualSwiper.autoplay.stop();
    this.isAutoplay();
  },
  isAutoplay: function isAutoplay() {
    if (mainVisualSwiper.autoplay.running) {
      jQuery('.main-visual').attr('data-autoplay', 'true');
    } else {
      jQuery('.main-visual').attr('data-autoplay', 'false');
    }
  }
};

// FAQ Swiper
var faqSwiper;
var faq = {
  init: function init() {
    if (!jQuery('.faq-swiper').length) return;
    this.swiper();
    this.handler();
  },
  handler: function handler() {
    jQuery('.faq-swiper').on('click', '[role="tab"]', function (e) {
      var $this = jQuery(e.currentTarget);
      var idx = $this.parent().index();
      var $tabpannel = jQuery('.faq-accordion-pannel');
      $this.attr('aria-selected', true).parent().siblings().find('[role="tab"]').attr('aria-selected', false);
      $tabpannel.find('.tab-pannel').eq(idx).removeAttr('hidden').siblings().attr('hidden', true);
      faqSwiper.slideTo(idx - 1, 400);
    });
  },
  swiper: function swiper() {
    faqSwiper = new Swiper('.faq-swiper', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      freeMode: true,
      simulateTouch: false,
      watchOverflow: true,
      navigation: {
        nextEl: '.faq-swiper-button-next',
        prevEl: '.faq-swiper-button-prev'
      }
    });
  }
};

// Star Score
var starScore = {
  init: function init() {
    this.handler();
  },
  handler: function handler() {
    var _this17 = this;
    jQuery('.star-point').each(function (i, v) {
      if (jQuery(v).hasClass('is-js')) return;
      jQuery(v).on('click', '.star-point-button', function (e) {
        _this17.click(e);
      });
      jQuery(v).addClass('is-js');
    });
  },
  click: function click(e) {
    var $this = jQuery(e.currentTarget);
    var idx = $this.index();
    $this.parent().attr('data-star', idx + 1);
    $this.parent().next('.star-point-score').find('em').text(idx + 1);
  },
  update: function update() {
    var _this18 = this;
    jQuery('.star-point').not('.is-js').each(function (i, v) {
      jQuery(v).on('click', '.star-point-button', function (e) {
        _this18.click(e);
      });
      jQuery(v).addClass('is-js');
    });
  }
};
var scrollSpy = {
  init: function init() {
    this.handler();
  },
  handler: function handler() {
    var _this19 = this;
    jQuery('.scroll-spy-js').on('click', '[role=button]', function (e) {
      return _this19.click(e);
    });
  },
  click: function click(e) {
    var $this = jQuery(e.currentTarget);
    var idx = $this.index();
    var $spySection = jQuery('.section[data-spy-section]');
    var pageY = idx > 0 ? $spySection.eq(idx).offset().top - 68 : $spySection.eq(idx).offset().top - 68;

    // $this.addClass('is-active').siblings().removeClass('is-active');
    gsap.to(jQuery('html, body'), {
      duration: 0.4,
      scrollTop: pageY
    });
  },
  scroll: function scroll() {
    if (!jQuery('.scroll-spy-js').length) return;
    var st = window.pageYOffset;
    var spyIdx;
    var $container = jQuery('.scroll-spy-js').parent();
    if (st >= $container.offset().top) {
      $container.addClass('is-fixed');
    } else {
      $container.removeClass('is-fixed');
    }
    if (st <= jQuery('.section[data-spy-section]').eq(0).offset().top) {
      spyIdx = 0;
    }
    var tabH = jQuery('.scroll-spy-js').outerHeight(true);
    jQuery('.section[data-spy-section]').each(function (i) {
      if (st >= jQuery('.section[data-spy-section]').eq(i).offset().top - tabH && st < jQuery('.section[data-spy-section]').eq(i).offset().top + jQuery('.section[data-spy-section]').outerHeight(true) - tabH) {
        spyIdx = i;
      }
    });
    jQuery('.scroll-spy-js').find('[role=button]').eq(spyIdx).addClass('is-active').siblings().removeClass('is-active');
  }
};
var summaryScript = {
  init: function init() {
    this.handler();
  },
  handler: function handler() {
    var _this20 = this;
    jQuery('.script-button').on('click', 'button', function (e) {
      return _this20.click(e);
    });
  },
  click: function click(e) {
    var $this = jQuery(e.currentTarget);
    $this.parent().toggleClass('is-active');
    jQuery('.script-content').toggleClass('is-active');
  }
};

// Sitemap
var sitemap = {
  init: function init() {
    this.handler();
  },
  handler: function handler() {
    var _this21 = this;
    jQuery('.sitemap').on('click', '.sitemap-button', function (e) {
      return _this21.click(e);
    });
  },
  click: function click(e) {
    var $this = jQuery(e.currentTarget);
    $this.parent().toggleClass('is-active');
  }
};

// Search Filter
var searchFilter = {
  scroll: function scroll() {
    if (!jQuery('.search-filter-fixed-container').length) return;
    var st = window.pageYOffset;
    var $sf = jQuery('.search-filter-fixed-container');
    var move = st >= $sf.offset().top;
    if (move) {
      $sf.addClass('is-fixed');
    } else {
      $sf.removeClass('is-fixed');
    }
  }
};

// getImgRatio
var imgRatio = {
  init: function init() {
    this.check();
  },
  check: function check() {
    var $img = jQuery('.get-ratio');
    $img.removeClass('set-ratio');
    $img.removeAttr('style');
    $img.each(function (i, e) {
      var imgWidth = jQuery(e).width();
      var imgHeight = jQuery(e).height();
      var imgRatio = jQuery(e).width() / jQuery(e).height();
      var frameWidth = jQuery(e).parent().width();
      var frameHeight = jQuery(e).parent().height();
      var frameRatio = frameWidth / frameHeight;

      // 대상 비율이 이미지 비율보다 클 때
      if (frameRatio > imgRatio) {
        jQuery(e).addClass('set-ratio').css({
          width: frameWidth + 'px',
          height: frameWidth / imgRatio + 'px'
        });
      } else {
        jQuery(e).addClass('set-ratio').css({
          width: frameHeight * imgRatio + 'px',
          height: frameHeight + 'px'
        });
      }
    });
  }
};

// Spinner
var spinner = {
  show: function show() {
    jQuery('html').addClass('is-spinner');
    jQuery('.spinner-container').addClass('is-active');
  },
  hide: function hide() {
    jQuery('html').removeClass('is-spinner');
    jQuery('.spinner-container').removeClass('is-active');
  }
};

// let isPageScale = false;
// // Scale
// const pageScale = {
//   set(v) {
//     jQuery('html').css('font-size', v + 'px');
//     if (v === 0.8) {
//       isPageScale = true;
//     } else {
//       isPageScale = false;
//     }
//   },
// };

jQuery(window).scroll(function () {
  scrollSpy.scroll();
  searchFilter.scroll();
});

// jQuery(window).ready(function () {
//   initFn();
//   imgRatio.init();
//   setTimeout(function () {
//     datePicker.init();
//     imgRatio.init();
//     if (depth2Swiper !== undefined) depth2Swiper.update();
//     if (subMainVisual !== undefined) subMainVisual.update();
//     if (faqSwiper !== undefined) faqSwiper.update();
//   }, 1000);
// });

// init
var initFn = function initFn() {
  // pageScale.set(0.8);
  // mainVisual.init();
  // mainCard.init();
  modal.init();
  formTextarea.init();
  imgRatio.init();
  sitemap.init();
  faq.init();
  myLearningStatus.init();
  summaryScript.init();
  scrollSpy.init();
  starScore.init();
  subMainVisualSwiper.init();
  postSort.init();
  accordion.init();
  post.init();
  gnbAction.init();
  depth2.init();
  depth3.init();
  appHeader.init();
  tab.init();
  tooltip.init();
  formSelect.init();
  formSearch.init();
  contentAside.handler();
  contentAside["break"]();
  signInfo.init();
  form.init();
  jQuery(window).scroll(function () {
    contentAside["break"]();
  });
};

// IIFE
(function () {
  if (location.href.indexOf('main.do') == -1) {
    initFn();
  }
  imgRatio.init();
  setTimeout(function () {
    datePicker.init();
    imgRatio.init();
    if (depth2Swiper !== undefined) depth2Swiper.update();
    if (subMainVisual !== undefined) subMainVisual.update();
    if (faqSwiper !== undefined) faqSwiper.update();
  }, 1000);
  jQuery('.board-header-desc').each(function (e, v) {
    console.log(e, v);
    if (jQuery(v).html() == '') {
      jQuery(v).hide();
    }
  });
})();